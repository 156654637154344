<template>
    <full-screen>
        <div 
            id="heroContainer"
            class="section hero" 
            ref="heroContent">
                <div class="hero-container">
                    <div 
                        id="heroContent"
                        class="hero-content">
                        <div class="hero-content-box">
                            <div>
                                <h1 
                                    class="title white"
                                    v-html="text.hero.title"></h1>
                                <p 
                                    class="standard-text subtitle white"
                                    v-html="text.hero.description"></p>
                                <div class="row-action div__playVideo">
                                    <div @click="openVideo">
                                    <img alt="Play video" class="broken" src="~@/assets/img/ico-func-white-play.svg" />
                                    <p class="medium-title white call-to broken">{{ text.hero.action }}</p>
                                    </div>
                                </div>
                            </div>
                            <!--re-playlist :onclick="openVideo"></!--re-playlist-->
                            <div class="offerBadgeContainer">
                                <img class="offerBadge" alt="Badge pagamenti" src="@/assets/img/badge_RCN.svg"/>
                                <img class="offerBadgeProntaConsegna" alt="Badge pronta consegna" src="@/assets/img/badge_prontaconsegna.svg"/>
                            </div>
                        </div>
                    </div>
                </div>
            <video-player aria-label="Video player"></video-player>
        </div>
        
        <div 
            ref="scroll"
            class='icon-scroll c-scrolldown' 
            @click="scrollTo"
            v-if="mq.current == 'desktop' || mq.current == 'bigDesktop'">
                <div class="c-line"></div>
        </div>
        <div id="mobilePointer"></div>
    </full-screen>
</template>

<script>
    import VideoPlayer from '../VideoPlayer/VideoPlayer'
    import text from '../../assets/text/text'
    import FullScreen from '../FullScreen/FullScreen'
    //import RePlaylist from '../RePlaylist/RePlaylist'

    export default {
        inject: ["mq"],
        name: 'Hero',
        components: {
            VideoPlayer,
            FullScreen
        },
        data: () => ({
            text: text,
            scene: null
        }),
        mounted: function(){
            window.addEventListener('resize', this.resizeHandler)
            this.resizeHandler()
        },
        destroy: function(){
            window.removeEventListener('resize', this.resizeHandler)
        },
        methods: {
            scrollTo: function(){
                this.$emit('scrollTo')
            },
            openVideo: function(){
                this.$emit('openFullVideo')
            },
            resizeHandler: function(){
                const { 
                    heroContent,
                    scroll } = this.$refs
                
                if( (this.mq.current == 'desktop' || this.mq.current == 'bigDesktop') && this.scene == null ){
                    if(heroContent && scroll){
                        /* this.scene = new ScrollMagic.Scene({ triggerElement: heroContent, triggerHook: 'onLeave', duration: 200, offset: 200})
                            //.addIndicators()
                            .setTween(scroll, {autoAlpha: 0})
                            .addTo(this.controller) */
                            //console.log("scrollmagic was here");
                    }

                }else{
                    if(this.scene){
                        this.scene.destroy()
                        this.scene = null
                    }
                }
            }
        }
    }
</script>