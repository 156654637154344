<template>
  <div class="modal-text-content" style="text-align: left">
    <h3 id="titlePrivacy" class="inner-title">
      <span class="red">Privacy Policy per i visitatori del sito</span>
    </h3>
    <p id="subtitlePrivacy" class="standard-text subtitle">
      <span>con Informativa ai sensi dell’ART. 13 REG. UE N. 679/2016</span>
    </p>

    <div>
      <p
        class="standard-text"
      >Repower Vendita Italia spa con sede legale in Milano, via Uberti 37 (cap. 20129), codice fiscale e partita iva 13181080154, in persona del legale rappresentante p.t., in qualità di titolare del trattamento (in seguito “Titolare”) dei dati personali degli utenti/visitatori che consultano il sito internet chargingnet.repower.com, La invita a prendere visione di questa informativa ai sensi dell'art. 13 del Reg. UE n. 679/2016 (d’ora in avanti “GDPR”), che riguarda il trattamento dei Suoi dati personali, nel rispetto delle normative vigenti e secondo quanto di seguito meglio specificato.</p>

      <p
        class="standard-text"
      >La presente informativa è resa esclusivamente per il sito internet chargingnet.repower.com (in seguito “Sito”) e non anche per altri siti web eventualmente consultati dall’utente tramite link.</p>
      <ol type="1" class="external--list">
        <li>
          <p class="standard-text">
            <strong>OGGETTO DEL TRATTAMENTO E CATEGORIE DI DATI TRATTATI</strong>
            <br />Il Titolare tratterà i dati personali identificativi (a titolo meramente esemplificativo: nome, cognome, indirizzo, numero di telefono, coordinate bancarie e altri dati di pagamento, e-mail, copie di documenti, oltre ad ogni informazione fornita in sede di compilazione degli appositi form presenti all’interno del Sito - in seguito anche “Dati”) da Lei liberamente comunicati al fine di usufruire di specifici servizi offerti dal Sito, che dovranno essere necessariamente acquisiti al fine di dar seguito alle Sue richieste.
          </p>
          <p
            class="standard-text"
          >Il Titolare tratterà inoltre tutti quei dati accessibili attraverso i sistemi informatici e le procedure software preposte al funzionamento del Sito stesso (dati di navigazione), che parimenti dovranno essere acquisiti al fine di controllare il funzionamento e l’uso del sito, per ragioni di sicurezza e rapporti con le autorità di controllo, per l’adempimento di ogni altro obbligo legale e contrattuale legato alla gestione del sito, oltre che, previo Suo consenso, per attività di marketing e profilazione svolte attraverso c.d. “cookie di profilazione” - per i quali si rimanda alla Cookie Policy.</p>
        </li>
        <li>
          <p class="standard-text">
            <strong>FINALITÀ, BASE GIURIDICA DEL TRATTAMENTO E CONSEGUENZE DELLA MANCATA COMUNICAZIONE DEI DATI</strong>
            <br />I Suoi Dati sono trattati in modo lecito e secondo correttezza per le finalità nel seguito descritte.
          </p>
          <ol type="A" style="list-style-type: upper-alpha;padding: 0">
            <li>
              <p class="standard-text">
                <a name="2A"></a>
                <strong>Gestione di specifici servizi offerti dal Sito, del rapporto precontrattuale e contrattuale eventualmente instauratosi e di obblighi legali.</strong>
              </p>
              <ul class="decorated-list">
                <li>
                  <p class="standard-text">
                    <strong>A.1. Necessità di presentarle offerte di fornitura gas naturale e/o di energia elettrica ovvero altri servizi a valore aggiunto, eseguire attività precontrattuali e/o eseguire il contratto su Sua richiesta.</strong>
                    <br />Tale necessità rappresenta la base giuridica che legittima il conseguente trattamento, in quanto per il Titolare è necessario presentarLe le offerte di fornitura di gas naturale e/o energia elettrica, dare seguito alla Sua richiesta di instaurare un rapporto contrattuale di fornitura, procedere all’espletamento di tutte le attività di connessione da Lei richiesti, porre in essere ogni azione finalizzata all’adempimento delle obbligazioni reciproche nascenti dalla sottoscrizione del contratto, ovvero adempiere alla sua richiesta di fornitura di altri servizi a valore aggiunto connessi alla sostenibilità e al risparmio energetico. Il Titolare avrà inoltre la necessità di gestire le attività di tesoreria afferenti al rapporto contrattuale (con relativo trattamento - ai termini di legge – delle coordinate bancarie e/o di altri dati di pagamento), in base a quanto previsto dai termini e dalle condizioni generali e/o da altre specifiche condizioni contrattuali concordate, nonché predisporre le misure finalizzate alla tutela contro il rischio di credito, incluse le attività finalizzate all’identificazione del Cliente e della sua affidabilità finanziaria.
                    <br />Il conferimento dei dati necessari a tali fini rappresenta, di caso in caso, un obbligo contrattuale o un requisito necessario alla conclusione del Contratto. In caso di mancato conferimento il Titolare potrebbe trovarsi nell’impossibilità di instaurare il rapporto contrattuale o di dare esecuzione allo stesso.
                    <br />Nel caso in cui, nell’ambito del rapporto contrattuale e delle tipologie di servizi richiesti, alcune specifiche finalità del trattamento siano stabilite dal Cliente (ad esempio nel caso in cui il Cliente richieda a Repower Vendita Italia S.p.A. servizi aggiuntivi che implichino la possibilità per Repower Vendita Italia S.p.A. di interagire, su esplicito mandato e per conto del Cliente, con soggetti terzi o autorità), Repower Vendita Italia S.p.A. sarà nominata dal Cliente, quale Responsabile del Trattamento limitatamente a dette finalità.
                    <br />Rientrano nella predetta categoria di dati anche le informazioni (nome, cognome, indirizzo e-mail, ed ogni altra informazione acquisita attraverso i form compilati on line) volontariamente fornite dall’utente in caso di richieste di assistenza, consulenza o servizi formulate attraverso le sezioni “Il tuo personal trainer dell’energia” e “Il servizio clienti Repower” o “White Paper sulla mobilità sostenibile” del Sito. Questi dati dovranno essere parimenti acquisiti dal Titolare al fine di dar seguito alle Sue richieste.
                  </p>
                </li>
                <li>
                  <p class="standard-text">
                    <strong>A.2. Necessità di adempiere ad obblighi legali</strong>
                    <br />Tale necessità rappresenta la base giuridica che legittima il conseguente trattamento, in quanto il Titolare è tenuto all’adempimento di obblighi legali, contabili, fiscali, amministrativi e contrattuali connessi all’erogazione delle forniture o dei servizi richiesti, nonché alla corretta gestione dei rapporti con autorità, organi di controllo ed enti pubblici terzi per finalità connesse a particolari richieste, all’adempimento di obblighi di legge o ad altre procedure.
                    <br />Il conferimento dei dati necessari a tali fini rappresenta un obbligo legale. In caso di mancato conferimento il Titolare potrebbe trovarsi nell’impossibilità di instaurare il rapporto contrattuale e potrebbe avere l’obbligo di effettuare segnalazioni.
                  </p>
                </li>
                <li>
                  <p class="standard-text">
                    <strong>A.3. Candidature online</strong>
                    <br />Quanto alle informazioni (nome, cognome, indirizzo e-mail, dati presenti nel CV, contenuti acquisiti attraverso i form compilati on line, etc.) volontariamente fornite dall’utente in sede di invio spontaneo di curriculum vitae e di candidature on line tramite le sezioni “Cambia il mondo dell’energia con noi” e “L'energia che ci muove” del Sito, questi dati saranno utilizzati dal Titolare per valutare l’idoneità dell’utente rispetto ad una potenziale collaborazione o assunzione in azienda, avviando, se del caso, i processi di selezione. In caso di necessità da parte del Titolare di acquisire categorie particolari di dati di cui all’art. 9 del Regolamento UE n. 2016/679, il relativo trattamento sarà subordinato alla preventiva acquisizione di specifico consenso da parte dell’interessato.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p class="standard-text">
                <strong id="PAR2B">Marketing e comunicazioni commerciali</strong>
                <br />Promozione commerciale, vendita e miglioramento di prodotti e servizi, ricerche di mercato. Esclusivamente previo Suo specifico e distinto consenso (ex art. 7 GDPR), che Lei è libero di dare o meno, tali attività sono finalizzate a:
              </p>
              <ul class="decorated-list">
                <li>
                  <p class="standard-text">
                    <a id="2B1" name="2B1"></a>B.1. consentire al Titolare di svolgere ricerche di mercato e analisi tese a rilevare il grado di soddisfazione della clientela sulla qualità e tipologia dei servizi resi e di iniziative relative al miglioramento dei servizi forniti, nonché di inviarLe materiale promozionale e/o comunicazioni ed informazioni di natura commerciale e di marketing diretto sui nuovi prodotti messi in vendita e sui nuovi servizi offerti dal Titolare ovvero da soggetti terzi (tra i quali rientrano anche le altre società del Gruppo Repower), nonché sulle relative offerte, sugli sconti e su ogni altra iniziativa promozionale e di fidelizzazione a Lei riservata, per mezzo di sistemi di contatto tradizionali (posta cartacea ovvero chiamate tramite operatore);
                  </p>
                </li>
                <li>
                  <p class="standard-text">
                    <a id="2B2" name="2B2"></a>B.2. consentire al Titolare di svolgere ricerche di mercato e analisi tese a rilevare il grado di soddisfazione della clientela sulla qualità e tipologia dei servizi resi e di iniziative relative al miglioramento dei servizi forniti, nonché di inviarLe materiale promozionale e/o comunicazioni ed informazioni di natura commerciale e di marketing diretto sui nuovi prodotti messi in vendita e sui nuovi servizi offerti dal Titolare ovvero da soggetti terzi (tra i quali rientrano anche le altre società del Gruppo Repower), nonché sulle relative offerte, sugli sconti e su ogni altra iniziativa promozionale e di fidelizzazione a Lei riservata, con l’uso di sistemi automatizzati di chiamata o di comunicazione di chiamata senza l’intervento di un operatore, ovvero mediante posta elettronica e/o messaggi di tipo SMS (Short Message Service).
                    <br />Il trattamento dei Dati ai predetti fini (sia “B.1.” che “B.2.”) è ammesso in relazione alla libera circolazione dei dati come prevista dal GDPR e si può concretizzare in attività volte a soddisfare i legittimi interessi commerciali del Titolare, tra cui le attività di sviluppo commerciale da quest’ultimo svolte. Il conferimento dei Dati per dette finalità è facoltativo. Lei può quindi decidere di non conferire alcun dato o di negare successivamente la possibilità di trattare dati già forniti: in tal caso, non potrà ricevere comunicazioni commerciali e materiale promozionale inerenti ai servizi offerti dal Titolare.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p class="standard-text">
                <a id="2C" name="2C"></a>
                <strong>Erogazione di prodotti e servizi “su misura” ed elaborazione di informazioni relativi a preferenze, abitudini, scelte di consumo (profilazione).</strong>
                <br />
              </p>
              <ul class="decorated-list">
                <li>
                  <p class="standard-text">
                    C.1. Esclusivamente previo Suo specifico e distinto consenso (ex art. 7 GDPR), che Lei è libero di dare o meno, il Titolare potrebbe avere la necessità di elaborare informazioni relative a preferenze, abitudini, scelte di consumo finalizzate a suddividere gli interessati in gruppi omogenei per comportamenti o caratteristiche (profilazione), anche mediante l’impiego di tecniche o algoritmi e sistemi informatici evoluti e anche attraverso l’arricchimento dei dati, al fine di sviluppare, promuovere ed erogare prodotti e servizi “su misura” del Titolare ovvero di soggetti terzi (tra i quali rientrano anche le altre società del Gruppo Repower).
                    <br />La profilazione potrà avvenire anche tramite i c.d. “cookie di profilazione” per i quali si rimanda alla Cookie Policy, ovvero sulla base di dati da Lei liberamente forniti tramite il Sito.
                    <br />Il consenso è prestato distintamente per i cookie di profilazione e per i dati liberamente forniti dall’utente.
                    <br />Il conferimento dei Dati per dette finalità è facoltativo. Lei può quindi decidere di non conferire alcun dato o di negare successivamente la possibilità di trattare dati già forniti: in tal caso, non potrà ricevere comunicazioni commerciali dedicate.
                  </p>
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <p class="standard-text">
            <strong>MODALITÀ DEL TRATTAMENTO</strong>
            <br />Il trattamento dei Suoi Dati è realizzato per mezzo delle operazioni indicate all’art. 4 n. 2) GDPR e precisamente: raccolta, registrazione, organizzazione, strutturazione, conservazione, adattamento o modifica, estrazione, consultazione, uso, comunicazione mediante trasmissione, diffusione o qualsiasi altra forma di messa a disposizione, raffronto o interconnessione, limitazione, cancellazione o distruzione dei Dati.
            <br />I Suoi Dati sono sottoposti a trattamento sia cartaceo che elettronico.
            <br />I Dati acquisiti formano oggetto di trattamento, nel pieno rispetto delle norme di legge, nonché dei principi di liceità, correttezza, trasparenza, non eccedenza e tutela della Sua riservatezza e dei Suoi diritti.
            <br />Non è previsto il trattamento di Dati di soggetti minorenni.
          </p>
        </li>
        <li>
          <p class="standard-text">
            <strong>PERIODO DI CONSERVAZIONE DEI DATI</strong>
            <br />Il Titolare conserva i Dati nel rispetto delle leggi locali e delle politiche e procedure aziendali interne per il tempo necessario allo scopo di adempiere le finalità di cui sopra e soddisfare i propri legittimi interessi commerciali, obblighi giuridici ovvero per stabilire, esercitare o difendere diritti legali. Esaurita la necessità di conservazione dei Dati per detti scopi, i medesimi saranno eliminati in maniera sicura. Per maggiori informazioni sui termini di conservazione dei documenti, è disponibile a
            <a href="https://go.repower.com/ConservazioneDati" target="_blank">questo link</a> l’estratto della Politica di Conservazione dei Dati Personali di Repower Vendita Italia spa
          </p>
        </li>
        <li>
          <p class="standard-text">
            <strong>COMUNICAZIONE, DIFFUSIONE E ACCESSO AI DATI</strong>
            <br />I Suoi Dati potranno essere resi accessibili per le finalità sopra descritte:
          </p>
          <ul class="decorated-list">
            <li>
              <p
                class="standard-text"
              >a dipendenti e collaboratori del Titolare in Italia e all’estero, nella loro qualità di responsabili del trattamento e/o sub-responsabili, ovvero persone autorizzate al trattamento ai sensi del GDPR e/o soggetti muniti di specifiche funzioni e compiti ai sensi dell’art. 2-quaterdecies del D.Lgs. n. 196/2003;</p>
            </li>
            <li>
              <p
                class="standard-text"
              >ad altre Società del Gruppo Repower (controllanti, controllate e/o collegate) in Italia e all’estero e ai dipendenti e collaboratori delle stesse (ad esempio per finalità amministrative e contabili);</p>
            </li>
            <li>
              <p
                class="standard-text"
              >ad altre società terze o altri soggetti (a titolo meramente indicativo e non esaustivo, istituti di credito, intermediari finanziari, istituti assicurativi del credito, studi professionali, consulenti etc.) che svolgono attività in outsourcing per conto del Titolare, nella loro qualità di responsabili esterni del trattamento, tra cui i fornitori o i soggetti incaricati ad eseguire servizi accessori o strumentali alle finalità sopra indicate, con cui il Titolare stipuli appositi accordi.</p>
            </li>
          </ul>
          <p class="standard-text">
            Il Titolare si riserva inoltre la facoltà di rendere accessibili i dati personali con alcune terze parti, fra cui: provider IT per finalità di sviluppo di sistemi e assistenza tecnica; revisori e consulenti per accertare la conformità ai requisiti esterni e interni; enti legali, agenzie preposte all’applicazione della legge e parti in causa conformemente a obblighi giuridici di informativa o pretese; eventuali successori o partner commerciali del Titolare o di una società del gruppo del Titolare in caso di vendita, cessione o altre operazioni straordinarie; forze di polizia, forze armate ed altre amministrazioni pubbliche, per l’adempimento di obblighi previsti dalla legge, da regolamenti o dalla normativa comunitaria.
            <br />Nel caso in cui detti soggetti siano stabiliti in Paesi extra-UE il Titolare assicura che il trasferimento dei Dati extra-UE avverrà in conformità alle disposizioni di legge applicabili, previa stipula delle clausole contrattuali standard previste dalla Commissione Europea.
          </p>
        </li>
        <li>
          <p class="standard-text">
            <strong>TRASFERIMENTO DATI</strong>
            <br />I Dati saranno conservati su server ubicati all’interno dell’Unione Europea. Resta in ogni caso inteso che il Titolare, ove si rendesse necessario, ha la facoltà di condividere i dati anche la Capogruppo Svizzera Repower AG e/o di trasferire i Dati anche in altri ambiti extra-UE; in tal caso, il Titolare assicura sin d’ora che il trasferimento dei Dati extra-UE avverrà in conformità alle disposizioni di legge applicabili, previa stipula delle clausole contrattuali standard previste dalla Commissione Europea.
            <br />Il Titolare applicherà ai predetti trasferimenti tutte le tutele necessarie ai sensi della vigente normativa in materia di privacy.
          </p>
        </li>
        <li>
          <p class="standard-text">
            <strong>DIRITTI DELL’INTERESSATO</strong>
            <br />Nella Sua qualità di interessato, Lei ha i diritti di cui agli artt. 13, comma 2, lettere b), c) e d), 15, 16, 17, 18, 19 e 21 GDPR e precisamente i diritti di:
          </p>
          <ul class="decorated-list">
            <li>
              <p
                class="standard-text"
              >ottenere la conferma dell’esistenza o meno di Dati che La riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile;</p>
            </li>
            <li>
              <p
                class="standard-text"
              >ottenere l’indicazione: a) dell’origine dei Dati (qualora non siano stati ottenuti presso l’interessato); b) delle finalità e modalità del trattamento, nonché della relativa base giuridica; c) della logica applicata in caso di trattamento effettuato con l’ausilio di strumenti elettronici; d) degli estremi identificativi del Titolare, del Responsabile della protezione dei dati (ove nominato) e del rappresentante eventualmente designato ai sensi dell’art. 13, comma 1, GDPR; e) dei soggetti o delle categorie di soggetti ai quali i Dati possono essere comunicati o che possono venirne a conoscenza in qualità di rappresentanti designati nel territorio dello Stato e di responsabili;</p>
            </li>
            <li>
              <p
                class="standard-text"
              >ottenere: a) l’aggiornamento, la rettificazione ovvero, quando vi ha interesse, l’integrazione dei Dati; b) la cancellazione, la trasformazione in forma anonima o il blocco dei dati personali trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati personali sono stati raccolti o successivamente trattati; c) l’attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato;</p>
            </li>
            <li>
              <p
                class="standard-text"
              >opporsi, in tutto o in parte: a) per motivi legittimi al trattamento dei Dati che La riguardano, ancorché pertinenti allo scopo della raccolta; b) al trattamento di Dati che La riguardano a fini di invio di materiale promozionale o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale, mediante l’uso di sistemi automatizzati di chiamata senza l’intervento di un operatore mediante e-mail e/o mediante modalità di marketing tradizionali mediante telefono e/o posta cartacea. Si fa presente che il diritto di opposizione dell’interessato, esposto al precedente punto b), per finalità di marketing diretto mediante modalità automatizzate si estende a quelle tradizionali e che comunque resta salva la possibilità per l’interessato di esercitare il diritto di opposizione anche solo in parte. Pertanto, l’interessato può decidere di ricevere solo comunicazioni mediante modalità tradizionali ovvero solo comunicazioni automatizzate oppure nessuna delle due tipologie di comunicazione;</p>
            </li>
            <li>
              <p
                class="standard-text"
              >ove applicabili, ha altresì i diritti di cui agli artt. 16 - 21 GDPR (diritto di rettifica, diritto all’oblio, diritto di limitazione di trattamento, diritto alla portabilità dei dati, diritto di opposizione), nonché il diritto di reclamo all’Autorità Garante;</p>
            </li>
            <li>
              <p
                class="standard-text"
              >revocare in qualsiasi momento il proprio consenso eventualmente prestato.</p>
            </li>
            <p class="standard-text">
              Per quanto attiene al diritto alla portabilità dei dati, l’interessato può chiedere di ricevere o trasferire i propri dati personali in possesso del Titolare in un formato strutturato di uso comune e leggibile, per ulteriori usi personali ovvero per fornirli ad altri titolari del trattamento.
              <br />Con riferimento al rapporto contrattuale, in termini generali i dati che possono essere oggetto di portabilità sono i dati anagrafici e di contatto.
            </p>
          </ul>
        </li>
        <li>
          <p class="standard-text">
            <strong>MODALITÀ DI ESERCIZIO DEI DIRITTI</strong>
            <br />Lei potrà in qualsiasi momento esercitare i diritti o formulare una richiesta inviando: una raccomandata a.r. alla Repower Vendita Italia spa, partita iva 13181080154, con sede legale in via Uberti 37 20129 Milano, ovvero una e-mail all’indirizzo:
            <a href="mailto:privacy.it@repower.com">privacy.it@repower.com</a>.
            <br />Il termine per la risposta è di un mese. Il predetto termine può essere prorogato di due mesi in casi particolarmente complessi: ove ciò si verificasse, entro un mese il Titolare fornirà una comunicazione relativa ai motivi della proroga.
            <br />Il Titolare ha il diritto di richiedere le informazioni necessarie per l’identificazione del richiedente.
            <br />In termini generali l’esercizio dei diritti è gratuito, salvo il caso di richieste manifestamente infondate o eccessive, per cui il Titolare può riservarsi la facoltà di richiedere all’interessato un ragionevole contributo spese basato sui costi amministrativi da sostenere.
          </p>
        </li>
        <li>
          <p class="standard-text">
            <strong>TITOLARE, RESPONSABILE DELLA PROTEZIONE DEI DATI E RESPONSABILI DEL TRATTAMENTO</strong>
            <br />Il Titolare del trattamento è la società Repower Vendita Italia spa, partita iva 13181080154, con sede legale via Uberti 37 20129 Milano, in persona del legale rappresentante p.t.
            <br />Il Responsabile della protezione dei dati potrà essere contattato al seguente indirizzo di posta elettronica:
            <a href="mailto:privacy.it@repower.com">privacy.it@repower.com</a>.
            <br />L’elenco delle categorie dei Responsabili del trattamento è custodito presso la sede legale del Titolare.
          </p>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalPrivacy"
};
</script>