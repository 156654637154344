<template>
  <div id="app">
    <router-view/>
    <re-modal 
      ref="modal"
      :type="type" :scrollto="scrollto"></re-modal>
  </div>
</template>

<script>

import ReModal from './components/ReModal/ReModal'
import { EventBus } from './helpers/event-bus.js'
import { 
  viewportScroll, 
  documentHeight, 
  viewportHeight 
} from './helpers/helper.js'

export default {
  name: 'app',
  components:{
    ReModal
  },
  data: () => ({
      showPrivacy: false,
      type: '',
      scrollto: null,
      fixedBody: {
          state: false,
          position: 0
      }
  }),
  created: function() {
        EventBus.on('show-modal', (t) => {
          const { modal } = this.$refs
          if(t && t.type)
              this.type = t.type
              this.scrollto = t.id
              modal.openModal()
        })

        EventBus.on('fixed-body', () => {
            this.fixedBody.state = !this.fixedBody.state;
            //console.log("dispatched fixed body");
             
            if(this.fixedBody.state){
              this.position = viewportScroll()            
              document.body.className += ' ' + 'modal-open'
              document.body.style.top = '-' + this.position + 'px'
              document.getElementsByTagName('header')[0].className += ' hide'
              this.$store.commit({
                type: 'fullScreenHandler',
                value: true
              })
              //document.getElementsByTagName('header').className += 'hide'
            }else{
              document.body.classList.remove('modal-open')
              document.getElementsByTagName('header')[0].classList.remove('hide')
              document.body.style.top = '0px'
              window.scrollTo(0, this.position)
              this.$store.commit({
                type: 'fullScreenHandler',
                value: false
            })
            }
        });

        this.getPostId();

        window.addEventListener('resize', this.resizeHandler)
        this.resizeHandler();
    },
    destroyed: function() {
      window.removeEventListener('resize', this.resizeHandler)
      
    },
    methods: {
          resizeHandler: function(){
              let vh = window.innerHeight * 0.01;
              document.documentElement.style.setProperty('--vh', `${vh}px`);

              if(this.position){
                let delta = documentHeight() - viewportHeight()
                if(this.position > delta){
                    this.position = delta
                    if(this.$store.getters.getGlobalFullScreen)
                      document.body.style.top = '-' + this.position + 'px'
                }
              }
              
          },
          getPostId: function() {
            let _pageUrl = self.location.toString();
            let postId = _pageUrl.split('/').pop();
            if (parseInt(postId)) {
              // eslint-disable-next-line
              console.log(postId, 'Was provided as postId');
            }
          }
    }
}

</script>

<style lang="scss">
  @import 'style/_index.scss';
</style>
